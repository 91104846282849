a.nav_link:hover {
  color: #fff;

  background-color: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.1);

  border-radius: 10px;
  height: 2rem;
  backdrop-filter: blur(10px);
  color: rgba(0, 0, 0, 1);
}

.exploreBtn:hover {
  background-color: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.1);

  border-radius: 10px;
  transition: transform 0.2s cubic-bezier(0.1, 0, 0.5, 1);
}
