* {
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  --scrollbarBG: #0f0e13;
  --thumbBG: #722741;

  font: 400 100%/1.5 "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;

  -webkit-font-feature-settings: "kern" on, "liga";
  text-size-adjust: 100%;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: contextual common-ligatures;
}
body::-webkit-scrollbar {
  width: 10px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 5px;
  border: 0px solid var(--scrollbarBG);
}
.gradient-bg-home {
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, #722741 0, transparent 50%);
}

.main-div {
  width: 100%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background: rgb(0, 0, 0); */
  /* background: linear-gradient(135deg, rgba(131,58,180,1) 0%, rgba(0,0,0,1) 50%, rgba(255,230,65,1) 100%); */
}
.second-div {
  width: 100%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(176, 119, 209, 0.801);
  /* background: linear-gradient(135deg, rgba(131,58,180,1) 0%, rgba(0,0,0,1) 50%, rgba(255,230,65,1) 100%); */
}
.main-div-section {
  position: relative;
  padding-top: calc(1.625rem + 5.83333vw);
  padding-bottom: calc(1.625rem + 5.83333vw);
}
.main-div-container {
  margin: 0 auto;
}
.welcome-title {
  position: relative;
  z-index: 1;
  color: hsla(0, 0%, 100%, 0.5);
  line-height: calc(1.2em + 0.18182vw - 0.58182px);
  font-weight: 530;
  max-width: 24em;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 1rem;
}
.main-heading {
  max-width: 7em;
  margin: 1.5rem 0 0;
  background: linear-gradient(57deg, #ffffff 30%, rgb(150, 116, 158) 100%);

  font-size: calc(1.80975rem + 7.5575vw);
  text-align: center;
  display: block;

  box-sizing: border-box;
  letter-spacing: -0.055em;
  line-height: calc(1.11644em - 0.9392vw + 3.00543px);
  font-weight: 803;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  position: relative;
  z-index: 1;
  margin-top: calc(1.125rem + 1.16667vw);
  line-height: calc(1.53983em + 0.13429vw - 0.42972px);
  max-width: 30em;
  font-size: calc(1.04163rem + 0.38833vw);
  font-weight: 290;
  letter-spacing: calc(-0.00262em - 0.01139vw + 0.03646px);
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.bg {
  position: relative;
  width: 100%;
  filter: blur(10px);
  user-select: none;
}

img {
  border-style: none;
  width: auto;
  height: 0.9rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .main-div {
    padding-left: calc(1.125rem + 1.16667vw);
    padding-right: calc(1.125rem + 1.16667vw);
  }

  .main-heading {
    font-size: calc(1.40113rem + 3.74417vw);
  }

  .welcome-title {
    font-size: 0.65rem;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 320;
    color: hsla(0, 0%, 100%, 0.82);
  }
}

hr {
  border-top-color: #bbb;
  opacity: 0.5;
  margin: 1rem;
}

.footer_bottom_copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3d3d3d;
  font-size: 0.8rem;
  padding-top: 2rem;
  border-top: 1px solid #ffffff1a;
}

@media (max-width: 480px) {
  .footer_bottom_copy {
    flex-direction: column;
  }
}
